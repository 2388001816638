@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Apercu";
    src: url("/dist/fonts/ApercuPro-Regular.woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Apercu";
    src: url("/dist/fonts/ApercuPro-Medium.woff2");
    font-weight: 500;
  }

  @font-face {
    font-family: "Apercu";
    src: url("/dist/fonts/ApercuPro-Bold.woff2");
    font-weight: 700;
  }

  @font-face {
    font-family: "Apercu";
    src: url("/dist/fonts/ApercuPro-Black.woff2");
    font-weight: 900;
  }
}

html {
  overscroll-behavior: none;
  background-color: #0c0b2f;
}

.primary-infinite-scroll {
  animation: primary-scroll 120s linear infinite;
}
.secondary-infinite-scroll {
  animation: secondary-scroll 120s linear infinite;
}

@keyframes primary-scroll {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes secondary-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.test {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 400px;
  height: 400px;
  backdrop-filter: blur(10px);
}

.button {
  @apply bg-lightgreen hover:bg-darkgreen transition-all ease-linear duration-150 px-8 py-4 text-darkblue font-bold uppercase cursor-pointer;
}
